/**
 * configuration applied when BROCOLI_API_MODE = staging
 */
const $get = require('lodash.get')
const isVisible = ({ context }) => {
  const { state } = context.$store
  const userSegments = $get(state.auth.user, 'protected.segments', [])

  const isAuhtorized = userSegments.some((segment) => {
    return segment.name === 'authorized'
  })

  return !!isAuhtorized
}
module.exports = {
  authMode: ['providers'],
  authOAuthProviders: [
    [
      'WSSOKeycloak',
      [
        {
          realm: 'PointP-preprod',
          clientId: 'vi-pointp-preprod',
          buttonText: 'Connexion POINT.P',
        },
      ],
    ],
  ],
  useShell: [
    'bb-default',
    {
      mode: 'universes',
      universes: [
        {
          type: 'tag',
          tagId: '66f2bb78b44241b69a251252',
          i18n: {
            fr: {
              title: 'LE POINT PORTRAIT',
            },
          },
          heading: {
            color: '#FF9B2A',
            dark: true,
            buttonColor: 'white',
            buttonLight: true,
            canRadio: false,
          },
          isVisible,
        },
        {
          type: 'tag',
          tagId: '66f2bba7b44241244c251263',
          i18n: {
            fr: {
              title: 'LE POINT PERSPECTIVES',
            },
          },
          heading: {
            color: '#AB2711',
            dark: true,
            buttonColor: 'white',
            buttonLight: true,
            canRadio: false,
          },
          isVisible,
        },
        {
          type: 'tag',
          tagId: '66f2bbafb442419be1251269',
          i18n: {
            fr: {
              title: 'LE POINT PRODUIT',
            },
          },
          heading: {
            color: '#E75012',
            dark: true,
            buttonColor: 'white',
            buttonLight: true,
            canRadio: false,
          },
          isVisible,
        },
        {
          type: 'tag',
          tagId: '66f2bbc2b442417bc925126f',
          i18n: {
            fr: {
              title: 'LE POINT PRO',
            },
          },
          heading: {
            color: '#FF7911',
            dark: true,
            buttonColor: 'white',
            buttonLight: true,
            canRadio: false,
          },
          isVisible,
        },
        {
          type: 'tag',
          tagId: '66f2bbcab442418d92251275',
          i18n: {
            fr: {
              title: 'LE POINT PARTICULIERS',
            },
          },
          heading: {
            color: '#191919',
            dark: true,
            buttonColor: 'white',
            buttonLight: true,
            canRadio: false,
          },
          isVisible,
        },
        {
          type: 'tag',
          tagId: '66f2bbd321b1dc0726752293',
          i18n: {
            fr: {
              title: 'LE POINT PLANÈTE',
            },
          },
          heading: {
            color: '#34D985',
            dark: true,
            buttonColor: 'white',
            buttonLight: true,
            canRadio: false,
          },
          isVisible,
        },
        {
          type: 'tag',
          tagId: '66f2bbdcb44241f88225127b',
          i18n: {
            fr: {
              title: 'LE POINT PARTENAIRES',
            },
          },
          heading: {
            color: '#E75012',
            dark: true,
            buttonColor: 'white',
            buttonLight: true,
            canRadio: false,
          },
          isVisible,
        },
        {
          type: 'tag',
          tagId: '66f2bbe4b44241a495251281',
          i18n: {
            fr: {
              title: "LE POINT D'ESTELLE",
            },
          },
          heading: {
            color: '#D13915',
            dark: true,
            buttonColor: 'white',
            buttonLight: true,
            canRadio: false,
          },
          isVisible,
        },
      ],
    },
  ],
}
